// src/pages/HomePage.js
import React, { createRef, useState } from "react";
import {
  ActionType,
  PageContainer,
  ProColumns,
  ProTable,
} from "@ant-design/pro-components";
import { Space, Typography } from "antd";
import { useAPI } from "../../client-api/utils/api";
import { DevolutionsInvoicesNotesDataApi } from "../../client-api";
import labels from "./data-fields";
import { defaultFilter } from "../../components/utils";

const ClientDevolutionsNotes: React.FC = () => {
  const actionRef: any = createRef<ActionType>();
  const api = useAPI(DevolutionsInvoicesNotesDataApi);

  const [loading, setLoading] = useState(false);

  const renderText = (editData: string, data: string) => {
    if (data !== editData) {
      return (
        <Space direction="vertical" size={0}>
          <Typography.Text>{editData}</Typography.Text>
          <Typography.Text type="secondary" style={{ fontSize: 10 }}>
            {data}
          </Typography.Text>
        </Space>
      );
    }
    return editData;
  };

  const clientBillingColumns: ProColumns[] = [
    {
      title: "id",
      dataIndex: "id",
      hidden: true,
      hideInSearch: true,
    },
    {
      title: labels["editDataNfe"],
      dataIndex: "editDataNfe",
      ellipsis: true,
      width: 100,
      ...defaultFilter("editDataNfe"),
      renderText: (text, record) => renderText(text, record.dataNfe),
    },
    {
      title: labels["editDataDevolutionNfe"],
      dataIndex: "editDataDevolutionNfe",
      ellipsis: true,
      width: 100,
      ...defaultFilter("editDataDevolutionNfe"),
      renderText: (text, record) => renderText(text, record.dataDevolutionNfe),
    },
    {
      title: labels["editDataIdentifierNumber"],
      dataIndex: "editDataIdentifierNumber",
      ellipsis: true,
      width: 100,
      ...defaultFilter("editDataIdentifierNumber"),
      renderText: (text, record) =>
        renderText(text, record.dataIdentifierNumber),
    },
    {
      title: labels["editDataBatch"],
      dataIndex: "editDataBatch",
      ellipsis: true,
      width: 100,
      ...defaultFilter("editDataBatch"),
      renderText: (text, record) => renderText(text, record.dataBatch),
    },
    {
      title: labels["editDataCustomerName"],
      dataIndex: "editDataCustomerName",
      ellipsis: true,
      width: 30,
      ...defaultFilter("editDataCustomerName"),
      renderText: (text, record) => renderText(text, record.dataCustomerName),
    },
    {
      title: labels["editDataMultiplierIdentifierNumber"],
      dataIndex: "editDataMultiplierIdentifierNumber",
      ellipsis: true,
      width: 90,
      ...defaultFilter("editDataMultiplierIdentifierNumber"),
      renderText: (text, record) =>
        renderText(text, record.dataMultiplierIdentifierNumber),
    },
    {
      title: labels["editDataWeightKq"],
      dataIndex: "editDataWeightKq",
      ellipsis: true,
      width: 90,
      renderText: (text, record) => renderText(text, record.dataWeightKq),
    },
  ];

  return (
    <PageContainer title={false}>
      <ProTable
        // antd site header height
        scroll={{ x: 2500 }}
        sticky={{ offsetHeader: 56 }}
        loading={loading}
        columns={clientBillingColumns}
        actionRef={actionRef}
        // params={filteredInfo}
        request={(params, sorter, filter) => {
          setLoading(true);
          const filterInvoices = { ...filter, ...params };
          delete filterInvoices.current;
          delete filterInvoices.pageSize;
          const getItems = api.devolutionsInvoicesNotesDataControllerFindAll({
            page: params?.current,
            pageSize: params?.pageSize,
            ...filterInvoices,
          });
          return getItems
            .then((data) => {
              return {
                data: data.list,
                success: true,
                total: data.total,
              };
            })
            .finally(() => setLoading(false));
        }}
        rowKey="id"
        pagination={{ defaultPageSize: 20 }}
        search={false}
        dateFormatter="string"
      />
    </PageContainer>
  );
};

export default ClientDevolutionsNotes;
