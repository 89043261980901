import { Button, Input, InputRef, Space } from "antd";
import { ClearOutlined, CheckOutlined } from "@ant-design/icons";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { useEffect, useRef } from "react";

const DropdownContent = (props: FilterDropdownProps) => {
  const { setSelectedKeys, selectedKeys, confirm, clearFilters, close } = props;
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Foca no input ao montar
    }
  }, []);

  return (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <Space.Compact block size="small">
        <Input
          placeholder={`Search`}
          value={selectedKeys[0]}
          ref={inputRef}
          onPressEnter={() => {
            close();
            confirm({ closeDropdown: true });
          }}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
        />
        <Button
          type="primary"
          size="middle"
          title="Filtrar"
          icon={<CheckOutlined />}
          onClick={() => {
            close();
            confirm({ closeDropdown: true });
          }}
        />
        <Button
          type="dashed"
          size="middle"
          title="Limpar filtro"
          icon={<ClearOutlined />}
          onClick={() => {
            clearFilters?.();
            confirm({ closeDropdown: true });
          }}
        />
      </Space.Compact>
    </div>
  );
};

export const defaultFilter = (dataIndex: string) => {
  return {
    filterDropdown: (props: FilterDropdownProps) => {
      return <DropdownContent {...props} />;
    },
    onFilter: (value: any, record: any) => true,
  };
};

export const textToIdentificationNumber = (value: string): string => {
  let newInNumber = value;

  if (newInNumber?.length > 11) {
    newInNumber = newInNumber.replace(/\D/g, ""); //  Remove tudo o que não é dígito
    newInNumber = newInNumber.replace(/^(\d{2})(\d)/, "$1.$2"); //  Coloca ponto entre o segundo e o terceiro dígitos
    newInNumber = newInNumber.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); //  Coloca ponto entre o quinto e o sexto dígitos
    newInNumber = newInNumber.replace(/\.(\d{3})(\d)/, ".$1/$2"); //  Coloca uma barra entre o oitavo e o nono dígitos
    newInNumber = newInNumber.replace(/(\d{4})(\d)/, "$1-$2"); //  Coloca um hífen depois do bloco de quatro dígitos
    return newInNumber;
  }

  newInNumber = newInNumber?.replace(/\D/g, ""); // Remove tudo o que não é dígito
  newInNumber = newInNumber?.replace(/(\d{3})(\d)/, "$1.$2"); // Coloca um ponto entre o terceiro e o quarto dígitos
  newInNumber = newInNumber?.replace(/(\d{3})(\d)/, "$1.$2"); //  Coloca um ponto entre o terceiro e o quarto dígitos
  //  De novo (para o segundo bloco de números)
  newInNumber = newInNumber?.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); // Coloca um hífen entre o terceiro e o quarto dígitos
  return newInNumber;
};
